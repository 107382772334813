import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";

import WorkIcon from "@mui/icons-material/Work";
import SettingsIcon from "@mui/icons-material/Settings";
import profile_Person from "../assets/images/avatar.png"
import LockIcon from "@mui/icons-material/Lock";
import PhoneIcon from "@mui/icons-material/Phone";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import cookMan from "../assets/images/cook_man.png"
import NotificationsIcon from '@mui/icons-material/Notifications';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";
import CategoryIcon from "@mui/icons-material/Category";
import DomainIcon from "@mui/icons-material/Domain";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "white", // Set background color to white
  color: "black",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));
const menuItems = [
  { text: "Home", icon: <HomeIcon /> , path :"/" },
  { text: "Companies", icon: <ApartmentIcon /> , path :"/companies"},
  { text: "Candidates", icon: <GroupsIcon />, path : '/candidates' },
  { text: "Jobs", icon: <WorkIcon /> , path :"/jobs" },
  { text: "Options", icon: <SettingsIcon /> , path :"/options",children: [
    { text: "Career levels", icon: <WorkOutlineIcon />, path: "admin/career-levels" },
    { text: "Company levels", icon: <BusinessIcon />, path: "admin/company" },
    { text: "Degree levels", icon: <SchoolIcon />, path: "admin/degree" },
    { text: "Functional Area", icon: <CategoryIcon />, path: "admin/areas" },
    { text: "Industries", icon: <DomainIcon />, path: "admin/industries" },
    { text: "JobCategories", icon: <DomainIcon />, path: "admin/jobcategories" },
    { text: "JobShifts", icon: <DomainIcon />, path: "admin/jobshifts" },
    { text: "Tags", icon: <DomainIcon />, path: "admin/tags" },
    { text: "JobTypes", icon: <DomainIcon />, path: "admin/jobtypes" },
    { text: "Positions", icon: <DomainIcon />, path: "admin/positions" },
    { text: "Currencies", icon: <DomainIcon />, path: "admin/currencies" },
    { text: "SalaryPeriods", icon: <DomainIcon />, path: "admin/salaryperiods" },
    { text: "Skills", icon: <DomainIcon />, path: "admin/skills" },
  ],},
  { text: "Subscribe", icon: <ThumbUpIcon />, path :"/subscribe" },
  { text: "Contact", icon: <PhoneIcon /> , path : "/contact"},
  { text: "Profile", icon: <PersonIcon />, path : '/profile' },
  { text: "Change Password", icon: <LockIcon />, path :'/changepassword' },
  { text: "Log out", icon: <PowerSettingsNewIcon />, path :'/logout' },
];
export default function MiniDrawer() {
    const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openSubmenu, setOpenSubmenu] = React.useState(null);
  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen); // Toggle open state
  };
  // const handleMenuClick = (item) => {
  //   if (openSubmenu === item.text) {
  //     setOpenSubmenu(null); // Close submenu if it's already open
  //   } else {
  //     setOpenSubmenu(item.text); // Open the clicked submenu
  //   }
  // };
  const handleMenuClick = (item) => {
    // Toggle submenu visibility
    setOpenSubmenu((prev) => (prev === item.text ? null : item.text));
  };

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

  return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <AppBar position="fixed" open={open}>
//         <Toolbar>
//         <IconButton
//             color="inherit"
//             aria-label="toggle drawer"
//             onClick={toggleDrawer}
//             edge="start"
//             sx={{
//               marginRight: 5,
//               color:'rgba(255, 31, 6, 1)'
//             }}
//           >
//             <MenuIcon />
//           </IconButton>
//             {/* Center Section: Spacer */}
//         <Box sx={{ flexGrow: 1 }} />

// {/* Right Section: FAB, Notification Icon, and Avatar */}
// <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//   {/* Floating Action Button */}
//   <Fab size="small" color="secondary" aria-label="add">
//     <AddIcon />
//   </Fab>

//   {/* Notification Bell Icon */}
//   <IconButton
//     color="inherit"
//     aria-label="notifications"
//     sx={{ color: 'black' }}
//   >
//     <NotificationsIcon />
//   </IconButton>

//   {/* Avatar */}
//   <Box sx={{ display: 'flex', alignItems: 'center', }}>
//     <Box sx={{display:'flex' , flexDirection:'column'}}>
//       <Typography variant="p">Welcome</Typography>
//       <Typography variant="p">admin</Typography>
//     </Box>
//     <Avatar alt="User Avatar" src={profile_Person} />
//   </Box>
 
// </Box>
//           {/* <Typography variant="h6" noWrap component="div">
//             Mini variant drawer
//           </Typography> */}
//         </Toolbar>
//       </AppBar>
//       <Drawer variant="permanent" open={open}>
//         <DrawerHeader>
//           {/* <IconButton onClick={toggleDrawer}>
//             {theme.direction === "rtl" ? (
//               <ChevronRightIcon />
//             ) : (
//               <ChevronLeftIcon />
//             )}
//           </IconButton> */}
//           <div style={{position:'relative'}}>
//         <Typography variant="h4" component="h1" gutterBottom sx={{width:"100%",maxWidth:'200px', height:'35px',fontSize:'40px',margin:'0',fontWeight:'700', color:'rgba(255, 31, 6, 1)'}}>
//          HelloCook
//         </Typography>
//         <div style={{position:'absolute',zIndex:'3', top:'0', left:'64%'}}>
//         <img src={cookMan} width="45px" height="48px"  alt="" />
//         </div>
//         </div>
//         </DrawerHeader>
//         <Divider />
//         {/* <List>
//           {menuItems.map((item, index) => (
//             <ListItem key={item.text}  onClick={() => navigate(item.path)} disablePadding sx={{ display: "block" }}>
//               <ListItemButton
//                 sx={[
//                   { minHeight: 48, px: 2.5 },
//                   open
//                     ? { justifyContent: "initial" }
//                     : { justifyContent: "center" },
//                 ]}
//               >
//                 <ListItemIcon
//                   sx={[
//                     { minWidth: 0, justifyContent: "center" },
//                     open ? { mr: 3 } : { mr: "auto" },
//                   ]}
//                 >
//                   {item.icon}
//                 </ListItemIcon>
//                 <ListItemText
//                   primary={item.text}
//                   sx={[open ? { opacity: 1 } : { opacity: 0 }]}
//                 />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List> */}
//         {/* <List>
//   {menuItems.map((item, index) => (
//     <React.Fragment key={item.text}>
//       <ListItem
//         onClick={() =>
//           item.children ? handleMenuClick(item) : navigate(item.path)
//         }
//         disablePadding
//         sx={{ display: "block" }}
//       >
//         <ListItemButton
//           sx={[
//             { minHeight: 48, px: 2.5 },
//             open ? { justifyContent: "initial" } : { justifyContent: "center" },
//           ]}
//         >
//           <ListItemIcon
//             sx={[
//               { minWidth: 0, justifyContent: "center" },
//               open ? { mr: 3 } : { mr: "auto" },
//             ]}
//           >
//             {item.icon}
//           </ListItemIcon>
//           <ListItemText
//             primary={item.text}
//             sx={[open ? { opacity: 1 } : { opacity: 0 }]}
//           />
//         </ListItemButton>
//       </ListItem>

//       {item.children && openSubmenu === item.text && (
//         <List sx={{ pl: 4 }}>
//           {item.children.map((subItem) => (
//             <ListItem
//               key={subItem.text}
//               onClick={() => navigate(subItem.path)}
//               disablePadding
//             >
//               <ListItemButton>
//                 <ListItemIcon>{subItem.icon}</ListItemIcon>
//                 <ListItemText primary={subItem.text} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//       )}
//     </React.Fragment>
//   ))}
// </List> */}

//         <Divider />
//       </Drawer>
//       <Box component="main" sx={{ flexGrow: 1,  }}>
//         <DrawerHeader />
//         <Box sx={{ marginBottom: 2, marginTop:4}}>
//         <Outlet />
//         </Box>
//       </Box>
//     </Box>
<Box sx={{ display: "flex" }}>
<CssBaseline />
<AppBar position="fixed" open={open}>
  <Toolbar>
    <IconButton
      color="inherit"
      aria-label="toggle drawer"
      onClick={toggleDrawer}
      edge="start"
      sx={{
        marginRight: 5,
        color: "rgba(255, 31, 6, 1)",
      }}
    >
      <MenuIcon />
    </IconButton>

    <Box sx={{ flexGrow: 1 }} />

    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Fab size="small" color="secondary" aria-label="add">
        <AddIcon />
      </Fab>
      <IconButton
        color="inherit"
        aria-label="notifications"
        sx={{ color: "black" }}
      >
        <NotificationsIcon />
      </IconButton>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="p">Welcome</Typography>
          <Typography variant="p">admin</Typography>
        </Box>
        <Avatar alt="User Avatar" src={profile_Person} />
      </Box>
    </Box>
  </Toolbar>
</AppBar>
<Drawer variant="permanent" open={open}>
  <DrawerHeader>
    <div style={{ position: "relative" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          width: "100%",
          maxWidth: "200px",
          height: "35px",
          fontSize: "40px",
          margin: "0",
          fontWeight: "700",
          color: "rgba(255, 31, 6, 1)",
        }}
      >
        HRMS
      </Typography>
      <div style={{ position: "absolute", zIndex: "3", top: "0", left: "64%" }}>
        <img src={cookMan} width="45px" height="48px" alt="" />
      </div>
    </div>
  </DrawerHeader>
  <Divider />
  <List>
    {menuItems.map((item) => (
      <React.Fragment key={item.text}>
        <ListItem
          disablePadding
          onClick={() =>
            item.children ? handleMenuClick(item) : navigate(item.path)
          }
        >
          <ListItemButton
            sx={[
              { minHeight: 48, px: 2.5 },
              open ? { justifyContent: "initial" } : { justifyContent: "center" },
            ]}
          >
            <ListItemIcon
              sx={[
                { minWidth: 0, justifyContent: "center" },
                open ? { mr: 3 } : { mr: "auto" },
              ]}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              sx={[open ? { opacity: 1 } : { opacity: 0 }]}
            />
            {item.children &&
              (openSubmenu === item.text ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
          </ListItemButton>
        </ListItem>
        {item.children && openSubmenu === item.text && (
          <List sx={{ pl: 4 }}>
            {item.children.map((subItem) => (
              <ListItem
                key={subItem.text}
                disablePadding
                onClick={() => navigate(subItem.path)}
              >
                <ListItemButton>
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText primary={subItem.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </React.Fragment>
    ))}
  </List>
</Drawer>
<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
</Box>
  );
}
