import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import  Login  from "./components/Login"
import Layout from './components/Layout'
import Companies from './components/Companies'
import Candidates from './components/Candidates'
import Subscribe from "./components/Subscribe";
import ChangePassword from "./components/ChangePassword";
import Contact from "./components/Contact";
import Jobs from "./components/Jobs";
import Profile from "./components/Profile";
import LogOut from "./components/Logout";
import Home from "./components/Home";
import CareerLevels from "./components/CareerLevels";
import Careers from "./components/Options/Careers.jsx"
import Company from "./components/Options/Company.jsx"
import Industries from "./components/Options/Industries.jsx"
import Areas from "./components/Options/Areas.jsx"
import Degrees from "./components/Options/Degrees.jsx"
import JobCategories from "./components/Options/JobCategories.jsx";
import JobShifts from "./components/Options/JobShifts.jsx";
import Tags from "./components/Options/Tags.jsx";
import JobTypes from "./components/Options/JobTypes.jsx";
import Positions from "./components/Options/Positions.jsx";
import Currencies from "./components/Options/Currencies.jsx";
import SalaryPeriods from "./components/Options/SalaryPeriods.jsx";
import Skills from "./components/Options/Skills.jsx";
const AppRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/"  element={<Layout />}>
        <Route index element={<Home/>}/>
        <Route path="companies" element={<Companies />} />
        <Route path="options" element={<CareerLevels />} />
        <Route path="admin/career-levels" element={<Careers />} />
        <Route path="admin/industries" element={<Industries />} />
        <Route path="admin/jobcategories" element={<JobCategories />} />
        <Route path="admin/jobshifts" element={<JobShifts />} />
        <Route path="admin/tags" element={<Tags />} />
        <Route path="admin/jobtypes" element={<JobTypes />} />
        <Route path="admin/positions" element={<Positions />} />
        <Route path="admin/currencies" element={<Currencies />} />
        <Route path="admin/salaryperiods" element={<SalaryPeriods />} />
        <Route path="admin/skills" element={<Skills />} />
        <Route path="admin/degree" element={<Degrees />} />
        <Route path="admin/areas" element={<Areas />} />
        <Route path="admin/company" element={<Company />} />
        <Route path="profile" element={<Profile />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="changepassword" element={<ChangePassword />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="candidates" element={<Candidates />} />
        <Route path="logout" element={<LogOut/>}/>
        </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
};

export default AppRoutes;
