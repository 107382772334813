import React from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
  Avatar,
  IconButton,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export default function Profile() {
  return (
    <Container>
      {/* Title */}
      <Box sx={{ paddingBottom: '10px' }}>
        <Typography variant="h6">Edit Profile</Typography>
      </Box>

      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={3}>
            {/* Profile Picture Section */}
            <Grid item xs={12} container justifyContent="center">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* Avatar */}
                <Avatar
                  alt="Profile Picture"
                  src="/static/images/avatar/1.jpg" // Replace with dynamic image path
                  sx={{ width: 100, height: 100 }}
                />
                <IconButton
                  color="primary"
                  component="label"
                  sx={{ marginTop: 1 }}
                >
                  <PhotoCamera />
                  <input hidden accept="image/*" type="file" />
                </IconButton>
              </Box>
            </Grid>

            {/* Name */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="First Name"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Last Name"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Email Address"
                type="email"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Phone */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Phone Number"
                type="tel"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Address"
                multiline
                rows={2}
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'rgba(141, 187, 65, 1)' }}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
