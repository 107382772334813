import React from 'react';
import CandidatesTable from "../customized/CandidatesTable";
import { Box, Container, Typography, Button } from '@mui/material';
import Search from "../assets/images/search.png";
import Filter from "../assets/images/sliders.png";

const Companies = () => {
  return (
    <div>
      {/* HEADER SECTION */}
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid gray",
            paddingBottom: '2%',
            mb: 3,
          }}
        >
          {/* Header Title */}
          <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px' }}>
            Recently Registered Candidates
          </Typography>

          {/* Image and Button Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2, // equal spacing between items
            }}
          >
            {/* Search and Filter Icons */}
            <img src={Search} alt="search" style={{ width: "24px", height: "24px" }} />
            <img src={Filter} alt="filter" style={{ width: "24px", height: "24px" }} />

            {/* Share Button */}
            <Button 
              variant="contained" 
              size="small" 
              sx={{
                backgroundColor: "#007BFF",
                color: "white",
                textTransform: "none", // to avoid uppercase text
                '&:hover': { backgroundColor: "#0056b3" }
              }}
              onClick={() => alert("Share functionality here")}
            >
              Share
            </Button>

            {/* Print Button */}
            <Button 
              variant="outlined" 
              size="small" 
              sx={{
                borderColor: "#007BFF",
                color: "#007BFF",
                textTransform: "none",
                '&:hover': { borderColor: "#0056b3", color: "#0056b3" }
              }}
              onClick={() => window.print()} // simple print functionality
            >
              Print
            </Button>
          </Box>
        </Box>

        {/* Table Section */}
        <CandidatesTable />
      </Container>
    </div>
  );
};

export default Companies;
