import React from 'react';
import { Box, TextField, Button, Typography, Link } from '@mui/material';
import cookMan from '../assets/images/cook_man.png'
import './Login.css'; 

const Login = () => {
  return (
    <Box className="login-container">
      <Box className="login-box" sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
        width: 300,
      }}>
        <div style={{position:'relative'}}>
        <Typography variant="h4" component="h1" gutterBottom sx={{fontSize:'60px',lineHeight:'90px',margin:'0',fontWeight:'700', color:'rgba(255, 31, 6, 1)'}}>
         HelloCook
        </Typography>
        <div style={{position:'absolute',zIndex:'3', top:'0', left:'66%'}}>
        <img src={cookMan} width="59px" height="75px"  alt="" />
        </div>
        </div>
       
       
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          slotProps={{
            input: {
              style: { backgroundColor: 'rgba(255, 255, 255, 1)' },
            },
          }}
          sx={{
            '& .MuiInputLabel-root': {
              color: 'grey', 
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black', 
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'grey', 
              },
              '&:hover fieldset': {
                borderColor: 'orange', 
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(255, 31, 6, 1)', 
              },
            },
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          slotProps={{
            input: {
              style: { backgroundColor: 'rgba(255, 255, 255, 1)' },
            },
          }}
          sx={{
            '& .MuiInputLabel-root': {
              color: 'grey', 
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black', 
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'grey', 
              },
              '&:hover fieldset': {
                borderColor: 'orange', 
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(255, 31, 6, 1)', 
              },
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 , backgroundColor:'rgba(255, 31, 6, 1)'}}
        >
          Login
        </Button>
        <Link href="#" underline="hover" sx={{ mt: 2, color: 'black' , fontWeight:'bold' }}>
          Forgot Password?
        </Link>
      </Box>
    </Box>
  );
};

export default Login;
