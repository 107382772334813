import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import CareerLevelsTable from  "../customized/CareerLevelsTable"
import Search from "../assets/images/search.png"
import Filter from "../assets/images/sliders.png"

const CareerLevels = () => {
  return (
    <div>
      {/*hEADER SECTION */}
      <Container>
      <Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid gray", 
    paddingBottom:'2%',
    mb: 3,
  }}
>
  {/* Header Title */}
  <Typography variant="h6" sx={{fontWeight:'400', fontSize:'20px'}}>Career Levels</Typography>

  {/* Image Section */}
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: 2, // equal spacing between images
    }}
  >
    <img src={Search} alt="search" style={{ width: "24px", height: "24px" }} />
    <img src={Filter} alt="filter" style={{ width: "24px", height: "24px" }} />
  </Box>
</Box>

     
      {/*Table Section  */}
      <CareerLevelsTable/>
      </Container>

    </div>
  )
}

export default CareerLevels