import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 170,
  height: 140,
  padding: theme.spacing(2),
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const CustomPaper = ({ icon, count, term }) => {
  return (
    <DemoPaper square={false}>
      <Typography variant="body1">{term}</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%', // Ensures alignment spans the full width
        }}
      >
        <Typography variant="h3">{count}</Typography>
        <Box sx={{ fontSize: 30 }}>
          <img src={icon} width="20px" height="20px" alt="" />
        </Box>
        
      </Box>
    </DemoPaper>
  );
};

export default CustomPaper;

