import React from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
} from '@mui/material';

export default function ChangePasswordForm() {
  return (
    <Container>
      <Box sx={{ paddingBottom: '10px' }}>
        <Typography variant="h6">Change Password</Typography>
      </Box>
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* Old Password */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Current Password"
                type="password"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* New Password */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="New Password"
                type="password"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Confirm New Password */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Confirm New Password"
                type="password"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' },
                }}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'rgba(141, 187, 65, 1)' }}
                >
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
