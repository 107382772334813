import React, { useState } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const Company = () => {
  const [careerData, setCareerData] = useState([
    { id: 1, level: "Entry Level", createdAt: "2024-11-01", updatedAt: "2024-11-10" },
    { id: 2, level: "Junior", createdAt: "2024-10-15", updatedAt: "2024-11-05" },
    { id: 3, level: "Mid Level", createdAt: "2024-09-20", updatedAt: "2024-10-25" },
    { id: 4, level: "Senior", createdAt: "2024-08-01", updatedAt: "2024-09-15" },
  ]);

  const [open, setOpen] = useState(false);
  const [newLevel, setNewLevel] = useState("");

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => {
    setOpen(false);
    setNewLevel("");
  };

  const handleAddNewLevel = () => {
    if (newLevel.trim()) {
      const currentDate = new Date().toISOString().split("T")[0];
      const newEntry = {
        id: careerData.length + 1,
        level: newLevel,
        createdAt: currentDate,
        updatedAt: currentDate,
      };
      setCareerData([...careerData, newEntry]);
      handleCloseDialog();
    } else {
      alert("Level name cannot be empty.");
    }
  };

  const handleShare = () => alert("Share functionality here!");
  const handlePrint = () => window.print();

  return (
    <div>
      <Box sx={{ mt: 4, mb: 4 }}>
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            borderBottom: "1px solid gray",
            pb: 2,
          }}
        >
          {/* Left Buttons: Share and Print */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#007BFF",
                color: "white",
                textTransform: "none",
                '&:hover': { backgroundColor: "#0056b3" },
              }}
              onClick={handleShare}
            >
              Share
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                borderColor: "#007BFF",
                color: "#007BFF",
                textTransform: "none",
                '&:hover': { borderColor: "#0056b3", color: "#0056b3" },
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Box>

          {/* Centered Title */}
          <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
            Career Levels
          </Typography>

          {/* Right Button: Add New Level */}
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#28a745",
              color: "white",
              textTransform: "none",
              '&:hover': { backgroundColor: "#218838" },
            }}
            onClick={handleOpenDialog}
          >
            Add New Level
          </Button>
        </Box>

        {/* Table Section */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f0f0f0' }}> {/* Grey background for header */}
                <TableCell sx={{ fontWeight: 'bold' }}>Level</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Updated At</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {careerData.map((career) => (
                <TableRow key={career.id}>
                  <TableCell>{career.level}</TableCell>
                  <TableCell>{career.createdAt}</TableCell>
                  <TableCell>{career.updatedAt}</TableCell>
                  <TableCell>
                    {/* Icon Buttons for Edit and Delete */}
                    <IconButton
                      color="primary"
                      onClick={() => alert(`Edit Level: ${career.level}`)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => alert(`Delete Level: ${career.level}`)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Footer Section: Pages Navigation */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
          gap: 2,
        }}
      >
        <Button variant="outlined" size="small">
          Previous
        </Button>
        <Typography>Page 1 of 3</Typography>
        <Button variant="outlined" size="small">
          Next
        </Button>
      </Box>

      {/* Add New Level Dialog */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Add New Level</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Level Name"
            type="text"
            fullWidth
            value={newLevel}
            onChange={(e) => setNewLevel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddNewLevel} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Company