import React from 'react';

const Logout = () => {
  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      // Perform logout logic, e.g., clearing tokens or redirecting
      console.log('User logged out');
      // Example: Redirect to the login page
      window.location.href = '/login';
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Logout</h2>
      <p style={styles.text}>Are you sure you want to logout?</p>
      <button style={styles.button} onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    textAlign: 'center',
    margin: '50px auto',
    padding: '20px',
    width: '300px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    color: '#333',
    marginBottom: '10px',
  },
  text: {
    color: '#555',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#ff4d4d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Logout;
