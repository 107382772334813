import React from 'react';
import { Grid2, Container } from '@mui/material';
import CustomPaper from '../customized/CustomPaper';
import HomeImg from "../assets/images/suitcase.png"
import People from "../assets/images/users.png"
import Building from "../assets/images/building.png"
import ThumbsUp from "../assets/images/thumbs-up.png"

const Home = () => {
  const papers = [
    { icon: HomeImg, count: 10, term: 'Available jobs' },
    { icon: People, count: 25, term: 'Candidates' },
    { icon: Building, count: 15, term: 'Companies' },
    { icon: ThumbsUp, count: 8, term: 'Applied jobs'},
    { icon: ThumbsUp, count: 140, term: 'Contact Us'},
    { icon: ThumbsUp, count: 56, term: 'Subscribers'},
  ];

  return (
    <>
      <Container sx={{
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      
      }} maxWidth={false} disableGutters>
      <Grid2 container spacing={2}>
        {papers.map((paper, index) => (
          <Grid2 xs={12} sm={6} md={3} key={index}>
            <CustomPaper icon={paper.icon} count={paper.count} term={paper.term} />
          </Grid2>
        ))}
      </Grid2>
    </Container>
    </>
  
  );
};

export default Home;
