import React from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
} from '@mui/material';

export default function CustomForm() {
  return (
    <Container>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '10px',
        }}
      >
        <Typography variant="h6">Create a Job</Typography>
        {/* Share and Print Buttons */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={() => alert('Share functionality here')}
          >
            Share
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </Box>
      </Box>

      {/* Form Section */}
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* First Row */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Name Of the Company"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Position Name"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Position Type"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>

            {/* Second Row */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Location"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Req.Experience"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Pay Range"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>

            {/* Third Row */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Req Skills"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: 'rgba(241, 241, 241, 1)' }, // Grey background
                }}
              />
            </Grid>

            {/* Fourth Row (Buttons) */}
            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'rgba(141, 187, 65, 1)' }}
                >
                  Save and Public Job
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
